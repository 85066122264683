import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormLabel,
  Radio,
  RadioProps,
  RadioGroup,
  FormControlLabel,
  Divider,
  Box,
  Container,
  Typography,
  MenuProps,
} from '@mui/material';
import {
  Translate,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';

import PopUpMenu from '../PopUpMenu/PopUpMenu';
import theme from '../../utils/theme';

const LanguageSelectionMenu = (props: MenuProps) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(i18n.language);

  const handleLanguageBtn = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    handleLanguageBtn((event.target as HTMLInputElement).value);
  };

  function StyledRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        color="primary"
        icon={<RadioButtonUnchecked />}
        checkedIcon={<RadioButtonChecked />}
        {...props}
      />
    );
  }

  return (
    <Box>
      <PopUpMenu menuIcon={<Translate titleAccess={t('ChangeLanguage')} />}>
        <Container>
          <FormLabel>
            <Typography variant="subtitle1">{t('ChangeLanguage')}</Typography>
          </FormLabel>
          <RadioGroup
            aria-label="position"
            name="position"
            defaultValue={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="en"
              control={<StyledRadio />}
              label={t('English') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="de"
              control={<StyledRadio />}
              label={t('German') as string}
              labelPlacement="end"
            />

            <Divider sx={{ marginY: theme.spacing(1) }} />

            <FormLabel>
              <Typography variant="subtitle1">{t('AutoTranslated')}</Typography>
            </FormLabel>

            <FormControlLabel
              value="fr"
              control={<StyledRadio />}
              label={t('French') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="hu"
              control={<StyledRadio />}
              label={t('Hungarian') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="it"
              control={<StyledRadio />}
              label={t('Italian') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="pt"
              control={<StyledRadio />}
              label={t('Portuguese') as string}
              labelPlacement="end"
            />
            <FormControlLabel
              value="es"
              control={<StyledRadio />}
              label={t('Spanish') as string}
              labelPlacement="end"
            />
          </RadioGroup>
        </Container>
      </PopUpMenu>
    </Box>
  );
};
export default LanguageSelectionMenu;
